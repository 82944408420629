<template>
  <div>
    <h4>Tabla encuestadores</h4>
    <b-table :items="items" :fields="fields" striped responsive="sm">
      <template #cell(pass)>
        <b-input placeholder="Inserte el password" type="password" @change="cambiar_Password" v-model="items.pass"></b-input>
        <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
      </template>



      <template #cell(estatus)>
        <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
        <b-form-checkbox @input="cambiarEstatus2" v-model="items.isActive" switch>
          Estatus del encuestador: <b>({{ items.isActive }})</b>
        </b-form-checkbox>
      </template>

    

      <template #row-details="row">
        <b-card>
          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Nombre:</b></b-col>
            <b-col>{{ row.item.nombre }}</b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Is Active:</b></b-col>
            <b-col>{{ row.item.isActive }}</b-col>
          </b-row>
        </b-card>
      </template>
    </b-table>









    
    <h4>Tabla encuestas</h4>
    <b-table :items="items2" :fields="fields2" striped responsive="sm">

      <template #cell(estatus)>
        <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
        <b-form-checkbox @input="cambiarEstatus" v-model="items2.isActive">
          Se encuentra activa la encuesta
        </b-form-checkbox>
      </template> 

      <template #cell(mostrar_resultados)="row">
        <b-button size="sm" @click="row.toggleDetails" class="mr-2">
            {{ row.detailsShowing ? 'Ocultar' : 'Mostrar'}} Resultados
        </b-button>
      </template>
      
    
      <template #cell(descargar_resultados) v-if="permisos == 'yes'">
        <b-button size="sm" @click="descargar_Resultados" class="mr-2">
            Descargar Resultados
        </b-button>
      </template>
    

      <template #row-details="row">
        <b-card>
          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Nombre de la encuesta:</b></b-col>
            <b-col>{{ row.item.nombre }}</b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Estatus:</b></b-col>
            <b-col>{{ row.item.isActive }}</b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Encuestador que aplico:</b></b-col>
            <b-col>{{ row.item.nombre_encuestador }}</b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Personas encuestadas:</b></b-col>
            <b-col>{{ row.item.n_personas }}</b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Tiempo de levantamiento (minutos):</b></b-col>
            <b-col>{{ row.item.tiempo }}</b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Numero de preguntas:</b></b-col>
            <b-col>{{ row.item.n_preguntas }}</b-col>
          </b-row>

          <!--
          <div v-if="permisos == 'yes'">
            <b-button @click="descargar_Resultados">
              Descargar resultados
            </b-button>
          </div>
          -->

        </b-card>

      </template>



      </b-table>

      <b-card class="mt-3" header="Tabla encuestadores">
          <pre class="m-0">{{ items }}</pre>
      </b-card>

      <b-card class="mt-3" header="Tabla encuestas">
          <pre class="m-0">{{ items2 }}</pre>
      </b-card>
  </div>  
</template>

<script>
import axios from 'axios'
  export default {
    data() {
      return {
        fields: ['nombre', 'pass', 'estatus'],
        items: [
          { isActive: true, nombre: "Encuestador 01", pass: '12/03/2024'},
          { isActive: false, nombre: "Encuestador 02", pass: '12/03/2024'},
          { isActive: false, nombre: "Encuestador 03", pass: '12/03/2024'},
          { isActive: true, nombre: "Encuestador 04", pass: '12/03/2024'}
        ],

        


        fields2: ['nombre', 'fecha_creacion', 'estatus', 'mostrar_resultados', 'descargar_resultados'],
        items2: [
          { isActive: "", nombre: "Encuesta 01", fecha_creacion: '12/03/2024', nombre_encuestador: "Ivan Lopez", n_personas: "30", tiempo: "60", n_preguntas: "30"},
          { isActive: "", nombre: "Encuesta 02", fecha_creacion: '12/03/2024', nombre_encuestador: "Sergio Rios", n_personas: "20", tiempo: "55", n_preguntas: "30"},
        ],
        permisos: 'yes',

        

      }

      
    },
    methods: {
      cambiar_Password(){
          alert('Se ha actualizado su contraseña.');

          axios({
            method: 'get',
            url: 'https://recaudacion.gobinet.mx/servicios',
            params:{"codeClient":"calimaya"},
            data: {
                "idToken": '',
                "usuario":this.usuario,
                "pass":this.pass
            }
          })
          .then(res => {
              this.resultado = res.data;
          }).catch(err => {
              //errores
              alert(err);
          });
          },

      descargar_Resultados(){
          alert('Ha comenzado la descarga. Verifique su carpeta de descargas para visualizar su archivo.');

          axios({
            method: 'get',
            url: 'https://recaudacion.gobinet.mx/servicios',
            params:{"codeClient":"calimaya"},
            data: {
                "idToken": '',
                "usuario":this.usuario,
                "pass":this.pass
            }
          })
          .then(res => {
              this.resultado = res.data;
          }).catch(err => {
              //errores
              alert(err);
          });
          },

        cambiarEstatus() {
          alert('Se ha actualizado el estatus del encuestador.');

          axios({
            method: 'get',
            url: 'https://recaudacion.gobinet.mx/servicios',
            params:{"codeClient":"calimaya"},
            data: {
                "idToken": '',
                "usuario":this.usuario,
                "pass":this.pass
            }
          })
          .then(res => {
              this.resultado = res.data;
          }).catch(err => {
              //errores
              alert(err);
          });
      },

      cambiarEstatus2() {
          alert('Se ha actualizado el estatus de la encuesta.');

          axios({
            method: 'get',
            url: 'https://recaudacion.gobinet.mx/servicios',
            params:{"codeClient":"calimaya"},
            data: {
                "idToken": '',
                "usuario":this.usuario,
                "pass":this.pass
            }
          })
          .then(res => {
              this.resultado = res.data;
          }).catch(err => {
              //errores
              alert(err);
          });
      },
    }

  
  }



  
</script>


